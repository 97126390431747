/* src/styles/tailwind.css */
@import 'tailwindcss/base';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('fonts/fonts.css');
@layer utilities {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .active{
    color: white;
    font-weight: bold;
  }

  .header-font {
      font-family: 'Akira Expanded', sans-serif;
  }


  .gradient-bg {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #8cc541d8 0%,#8cc5415b 50%, #00ff0000 100%);
    /* Change #00FF00 to your desired shades of green */
}

* {
  font-family: 'Poppins', sans-serif;
}

.lg-text {
  font-family: 'Poppins', sans-serif;
}
.dark-bg {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #0c1105d8 0%,#10140b3d 50%, #00ff0000 100%);
  /* Change #00FF00 to your desired shades of green */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.slide-in-top {
	-webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
